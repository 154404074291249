export const RESUME_EXPERIENCE = [
  {
    title: "Zapier, Senior Full Stack Engineer",
    dates: "Sep 2022 - Present",
  },
  {
    title: "Scanifly, Frontend Engineer",
    dates: "Feb 2021 - Sep 2022",
  },
  {
    title: "Freelance, Web Developer & UX Designer",
    dates: "July 2019 - June 2021",
  },
  {
    title: "Cloud Cover Music, UX Designer",
    dates: "June 2020 - May 2021",
  },
  {
    title: "Meltzer Mandl Archictects, Web Developer",
    dates: "July 2019 - May 2021",
  },
  {
    title: "NODA, Web Developer & Graphic Designer",
    dates: "Jan 2018 - May 2021",
  },
];

export const RESUME_EDUCATION = [
  {
    title: "University of Minnesota, Twin Cities",
    dates: "Aug 2016 - May 2020",
  },
];

export const RESUME_SKILLS = [
  {
    title: "AI Engineering",
    rating: 5,
  },
  {
    title: "Problem Solving",
    rating: 5,
  },
  {
    title: "Accessibility",
    rating: 5,
  },
  {
    title: "Design Thinking",
    rating: 5,
  },
  {
    title: "React",
    rating: 5,
  },
  {
    title: "CSS",
    rating: 5,
  },
  {
    title: "Jotai",
    rating: 4,
  },
  {
    title: "Redux",
    rating: 4,
  },
  {
    title: "React Native",
    rating: 3,
  },
];
