import "./About.css";
import image from "../../assets/maggie.jpg";
import { SocialLinks } from "../SocialLinks/SocialLinks";

export const About = () => {
  return (
    <div className="About">
      <div className="About-Wrapper">
        <div className="About-Text">
          <h1>Hey there! I'm Maggie.</h1>
          <p>
            I'm a creative software engineer who's passionate about AI and
            accessibility. I work as a senior full stack engineer at{" "}
            <a href="https://zapier.com/" target="_blank" rel="noreferrer">
              <span>Zapier</span>
            </a>
            , developing innovative AI features that help{" "}
            <i>make automation work for everyone.</i>
          </p>
          <p>
            I have a background in software engineering and UX design. I enjoy
            finding creative solutions to problems and typically approach my
            work with a design-first mentality.
          </p>
        </div>
        <SocialLinks />
      </div>
      <img alt="" src={image} />
    </div>
  );
};
